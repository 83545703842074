import React from 'react';
import { graphql, Link } from 'gatsby';

import NewsBanner from 'components/news/NewsBanner';
import SearchBox from 'components/search/SearchBoxSuggest';
import NewsBlockListPaginated from 'components/news/BlockListPaginated';
import AboutBlockPreview from 'components/about/BlockPreview';
import FormattedMessage from 'components/utils/FormattedMessage';

export const query = graphql`
    query($pageLang: String!, $categoryId: [String!], $teamId: String, $skip: Int!, $limit: Int!) {
        allSanityNews(
            filter: {
                language: {hreflang: {eq: $pageLang}},
                category: {_id: {in: $categoryId}},
                team: {elemMatch: {_id: {eq: $teamId}}}
                appOnly: {ne: true}
            }
            limit: $limit
            skip: $skip
            sort: {fields: publishDate, order: DESC},
        ) {
            nodes {
                _id
                slug {
                    current
                }
                appOnly
                showResult
                language {
                    hreflang
                }
                image {
                    teaserImage {
                        image {
                            ...ImageWithPreview
                        }
                        alt
                    }
                    defaultImage {
                        image {
                            ...ImageWithPreview
                        }
                        alt
                    }
                }
                video {
                    teaserVideoPreview {
                        image {
                            ...ImageWithPreview
                        }
                        alt
                    }
                    teaserVideo {
                        url
                    }
                }
                _createdAt
                publishDate
                title
                category {
                    _id
                    ref
                    title {
                        se
                        en
                    }
                }
                team {
                    _id
                    title {
                        se
                        en
                    }
                    axsType
                }
                _rawContent
            }
        }
    }
`;

const NewsList = ({ data, pageContext }) => {
    const {
        categoryTitle,
        pageLang,
        newsCategories,
        nextPagePath,
        numberOfPages,
        previousPagePath,
        hammarbyTeams: teams,
        teamTitle,
    } = pageContext;

    const news = data.allSanityNews.nodes || null;

    return (
        <>
            <NewsBanner />
            <ul className="tabs wrapper">
                <li className={!teamTitle ? 'defaultActive' : ''}>
                    <Link
                        activeClassName="active"
                        partiallyActive={false}
                        to={`/nyheter/${categoryTitle[pageLang]}`}>
                        <FormattedMessage id="global.allTeams" />
                    </Link>
                </li>

                {teams?.map(team => team.title && team.title[pageLang] && (
                    <li key={team._id}>
                        <Link
                            activeClassName="active"
                            to={`/nyheter/${team.title[pageLang]}/${categoryTitle[pageLang]}`}>
                            {team.title[pageLang]}
                        </Link>
                    </li>
                ))}
            </ul>

            <ul className="tabsSecondary wrapper">
                {newsCategories?.map(category => (
                    <li key={category._id}
                        className={category.title[pageLang] === categoryTitle[pageLang] ? 'defaultActive' : ''}
                    >
                        <Link
                            activeClassName="active"
                            to={teamTitle && teamTitle[pageLang]
                                ? `/nyheter/${teamTitle[pageLang]}/${category.title[pageLang]}`
                                : `/nyheter/${category.title[pageLang]}`
                            }
                        >
                            {category.title[pageLang]}
                        </Link>
                    </li>
                ))}
            </ul>
            <div className="wrapper">
                <SearchBox />
            </div>
            {news && news.length > 0 && (
                <NewsBlockListPaginated
                    title={categoryTitle[pageLang]}
                    items={news}
                    numberOfPages={numberOfPages}
                    previousPagePath={previousPagePath}
                    nextPagePath={nextPagePath}
                />
            )}

            <AboutBlockPreview />
        </>
    );
};

export default NewsList;
